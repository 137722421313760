$(document).ready(function() {
  // Custom Parallax
  $(".parallax").each(function() {
    var $obj = $(this);

    $(window).scroll(function() {
      var offset = $obj.offset();
      var yPos = -($(window).scrollTop() - offset.top) / $obj.data("speed");
      var bgPos = "50%" + yPos + "px";
      var width = "100%";
      var bgSize = "cover";

      $obj.css("background-position", bgPos);
      $obj.css("width", width);
      $obj.css("background-size", bgSize);
    });
  }, {passive: true});
});

window.onload = function() {
  checkOS()

  checkBrowser()

  sticky(200)

  contrast()

  ScrollOut({ // scroll-out
    threshold: .2,
    once: true
  })

  const menuButton = document.querySelector('.navbar-toggler')
  const menuContent = document.querySelector('.navbar-collapse')

  menuButton.addEventListener('click', () => {

    menuButton.classList.toggle('active')

    if (menuButton.classList.contains('active') == true) {
      menuContent.classList.remove('show')
    } else {
      menuContent.classList.add('show')
    }
  })

  document.getElementById('increaseFont').addEventListener('click', () => {
    fontSize(1)
  })

  document.getElementById('decreaseFont').addEventListener('click', () => {
    fontSize(-1)
  })

  lax.setup() // laxxx

  const updateLax = () => {
    lax.update(window.scrollY)
    window.requestAnimationFrame(updateLax)
  }

  window.requestAnimationFrame(updateLax)
}

function checkOS() { // Check user OS
  if (navigator.platform.match('iPhone|iPad|iPod') !== null) {
    document.body.setAttribute('class', 'ios')
  } else {
    document.body.setAttribute('class', 'default')
  }
}

function checkBrowser() { // Check user Browser
  let isEdge = navigator.userAgent.match('Edge')
  let isIe = navigator.userAgent.match('.NET')

  if (isEdge !== null) {
    document.body.classList.add('edge')
  } else if (isIe !== null) {
    document.body.classList.add('ie')
  } else {}
}

function sticky(offset) { // Sticky navigation
  let scrollPos = window.scrollY;

  const navbar = document.querySelector('.navbar');

  window.addEventListener('scroll', ()=> {
    scrollPos = window.scrollY;
    passive = true;

    if (scrollPos >= offset) {
      navbar.classList.add('sticky');
    } else {
      navbar.classList.remove('sticky');
    }
  });
}

function contrast() { // transfor greyscale
  const selector = document.getElementById('grey')
  const target = document.querySelector('html')

  selector.addEventListener('click', () => {
    const status = localStorage.getItem('config@grey')

    if (status === 'true') {
      localStorage.setItem('config@grey', false)
      target.classList.toggle('greyscale')
    } else {
      localStorage.setItem('config@grey', true)
      target.classList.toggle('greyscale')
    }
  })
}

function fontSize(val) { // Change Font Size
  const target = document.querySelector('html')
  let resizer = parseFloat(target.style.fontSize) + (val * 0.5) + 'px'

  if (target.style.fontSize == '') {
    target.style.fontSize = '10px'
  }

  if (resizer <= '12px') {
    target.style.fontSize = resizer
  }

  localStorage.setItem('config@px', resizer)
}

// Swiper
const mainSwiper = new Swiper ('.swiper', {
  slidesPerView: 1,
  direction: 'horizontal',
  effect: 'slide',
  speed: 300,
  loop: true,
  lazy: true,
  spaceBetween: 0,
  preloadImages: true,
  autoplay: {
    delay: 8000,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  // scrollbar: {
  //   el: '.swiper-scrollbar',
  // },
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 0
    },
    768: {
      slidesPerView: 1,
      spaceBetween: 0
    },
    1200: {
      slidesPerView: 1,
      spaceBetween: 0
    }
  }
})
